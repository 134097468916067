import React from "react"
import { Link } from "gatsby"

class FreeGuide extends React.Component {
  render() {
    return (
      <div className="free-guid sticky-top">
        <div className="free-resource">
          <div className="free-resource-inner">
            <h3 className="free-resource-title">FREE GUIDE</h3>
            <div
              className="free-resource-body bg-image"
              style={{
                backgroundImage: 'url("/assets/guide-bg.jpg")',
              }}
            >
              <h6>FREE GUIDE</h6>
              <h4>6 Alternatives To Spreadsheets &amp; Why You Need One</h4>
              <div className="free-resource-desc">
                <div className="free-resource-desc-inner">
                  88% of all spreadsheets have "significant" errors in them. Do
                  yours?
                </div>
              </div>
            </div>{" "}
            <Link
              className="free-resource-button"
              to="/6-alternatives-to-spreadsheets-and-why-you-need-one/"
            >
              DOWNLOAD NOW
              <i className="fa fa-long-arrow-right text-white" />
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default FreeGuide

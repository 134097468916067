import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

import JwPagination from "../components/pagination/pagination"
import Seo from "../components/seo"

import Search from "../components/search/search"
import FreeGuide from "../components/freeguide"
import { LazyLoadComponent } from "react-lazy-load-image-component"

import { GatsbyImage } from "gatsby-plugin-image"

import person from "../images/person-holder.jpg"
import NewsletterForm from "../components/form/newsletterform"
const slugify = require("slugify")

class SearchPageAll extends React.Component {
  constructor(props) {
    super(props)

    const {
      allSanityPost,
      allSanityKnowledgeBase,
      allSanityCaseStudy,
    } = this.props.data
    const allKnowledgeBase = allSanityKnowledgeBase.edges
    const allNews = allSanityPost.edges
    const allCaseStudies = allSanityCaseStudy.edges
    const queryString = this.props.location.search.replace("?=", "")
    const allSearch = [...allKnowledgeBase, ...allNews, ...allCaseStudies]

    let searchParam = allSearch.filter((item, idx) => {
      const queryParam = item.node.body
        ? item.node.body.toLowerCase().includes(queryString)
        : item.node.discription.toLowerCase().includes(queryString)
      return item.node.title.toLowerCase().includes(queryString) || queryParam
    })

    let latex = searchParam.map(({ node }) => node)
    this.onChangePage = this.onChangePage.bind(this)
    this.state = {
      pageOfItems: [],
      searchParam,
      latex,
      queryString,
      allNews,
    }
  }
  onChangePage(pageOfItems) {
    // update local state with new page of items
    this.setState({ pageOfItems })
  }

  render() {
    const { queryString, searchParam, allNews, pageOfItems } = this.state
    // console.log(JwPagination.pager)

    return (
      <Layout location={this.props.location}>
        <Seo
          title={`You searched for ${queryString} - Quandary Consulting Group`}
          description="Expand your bottom line. Exceed customer expectations. Experience efficiency like never before.Choose Quandary to guide your workflow optimization."
        />
        <div className="page-content mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mr-auto">
                {searchParam.length !== 0 ? (
                  <div className="intro mb-5">
                    {queryString !== "" && searchParam.length !== 0 ? (
                      <h3 className="font-weight-medium">
                        Found {searchParam.length} results for: {queryString}
                      </h3>
                    ) : (
                      <h3 className="font-weight-medium">
                        There where no results for your search.
                      </h3>
                    )}
                  </div>
                ) : (
                  <p>No Data</p>
                )}

                <div className="search-all-list">
                  <div className="category-list">
                    {queryString !== "" ? (
                      <>
                        {pageOfItems.map((item, idx) => {
                          const slug =
                            item.categories && item.categories.length > 0
                              ? item.categories[0].slug !== null &&
                                item.categories[0].slug !== undefined
                                ? item.categories[0].slug.current
                                : slugify(
                                    item.categories[0].title.toLowerCase()
                                  )
                              : "uncategorized"
                          return (
                            <LazyLoadComponent>
                              <article
                                className="category-list-item"
                                id={`result-${idx}`}
                              >
                                {(item.mainImage !== null &&
                                  item.mainImage !== undefined) ||
                                (item.bannerImage !== null &&
                                  item.bannerImage !== undefined) ? (
                                  <div className="category-featured">
                                    <div
                                      // className="post-image"
                                      style={{
                                        backgroundImage: `url(${
                                          item.mainImage &&
                                          item.mainImage.asset &&
                                          item.mainImage.asset.fluid &&
                                          item.mainImage.asset.fluid.src
                                            ? item.mainImage.asset.fluid.src
                                            : item.bannerImage &&
                                              item.bannerImage.asset &&
                                              item.bannerImage.asset.fluid &&
                                              item.bannerImage.asset.fluid.src
                                            ? item.bannerImage.asset.fluid.src
                                            : ""
                                        })`,
                                      }}
                                    >
                                      <Link
                                        to={
                                          item._type === "knowledgeBase"
                                            ? `/knowledge-base/${slug}/${item.slug.current}/`
                                            : item._type === "caseStudy"
                                            ? `case-study/${item.slug.current}/`
                                            : item.slug.current + "/"
                                        }
                                      ></Link>
                                    </div>
                                    <div className="category-meta">
                                      <div className="category-author-holder">
                                        <div className="category-author-name">
                                          {" "}
                                          <span>by</span>&nbsp;
                                          <Link
                                            to={
                                              item.author !== null &&
                                              item.author.name !== "Dev Team"
                                                ? `/author/${slugify(
                                                    item.author.name.toLowerCase()
                                                  )}/`
                                                : item.author.name ===
                                                  "Dev Team"
                                                ? "/"
                                                : "/"
                                            }
                                            title={`Posts by ${
                                              item.author
                                                ? item.author.name
                                                : "Dev Team"
                                            }`}
                                            rel="author"
                                          >
                                            {item.author
                                              ? item.author.name
                                              : "Dev Team"}
                                          </Link>
                                        </div>
                                        <div className="item-date mt-1">
                                          {item.publishedAt
                                            ? item.publishedAt
                                            : item._createdAt}
                                        </div>
                                      </div>
                                      <div className="category-author-image">
                                        <GatsbyImage
                                          style={{
                                            width: 43,
                                            height: 43,
                                          }}
                                          image={
                                            item.author !== null &&
                                            item.author.name !== "Dev Team" &&
                                            item.author.image !== null
                                              ? item.author.image.asset
                                                  .gatsbyImageData
                                              : item.author.name === "Dev Team"
                                              ? item.author.image.asset
                                                  .gatsbyImageData
                                              : person
                                          }
                                          alt={
                                            item.author
                                              ? item.author.name
                                              : "Dev Team"
                                          }
                                          imgClassName="author"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="category-holder">
                                  <div className="category-category w-100">
                                    {item &&
                                      item.categories &&
                                      item.categories.length !== 0 &&
                                      item.categories.map((ct, idx) => {
                                        const slug =
                                          ct.slug !== null &&
                                          ct.slug !== undefined
                                            ? ct.slug.current
                                            : slugify(ct.title.toLowerCase())
                                        return (
                                          <Link
                                            key={idx}
                                            className="text-small font-weight-medium text-uppercase"
                                            to={
                                              item._type === "knowledgeBase"
                                                ? `/knowledge-base/${slug}/`
                                                : item._type === "caseStudy"
                                                ? `/case-study-category/${slug}/`
                                                : `/category/${slug}/`
                                            }
                                          >
                                            {(idx ? ", " : "") + ct.title}
                                          </Link>
                                        )
                                      })}
                                  </div>
                                  <div className="w-100 mb-4">
                                    <h4 className="category-title mt-2">
                                      <Link
                                        to={
                                          item._type === "knowledgeBase"
                                            ? `/knowledge-base/${slug}/${item.slug.current}/`
                                            : item._type === "caseStudy"
                                            ? `/case-study/${item.slug.current}/`
                                            : item.slug.current === "search"
                                            ? "/"
                                            : `/${item.slug.current}/`
                                        }
                                      >
                                        {item.title}
                                      </Link>
                                    </h4>
                                    <div className="category-content">
                                      {item._type === "knowledgeBase" ? (
                                        <p>
                                          {item.metaDescription.slice(0, 96)}...
                                        </p>
                                      ) : item._type === "caseStudy" ? (
                                        <p>
                                          {item.discription
                                            .replace(/#/g, "")
                                            .slice(0, 96)}
                                          ...
                                        </p>
                                      ) : (
                                        <p>{item.shortDescription}</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="category-more">
                                    <Link
                                      to={
                                        item._type === "knowledgeBase"
                                          ? `/knowledge-base/${slug}/${item.slug.current}/`
                                          : item._type === "caseStudy"
                                          ? `/case-study/${item.slug.current}/`
                                          : item.slug.current === "search"
                                          ? "/"
                                          : `/${item.slug.current}/`
                                      }
                                    >
                                      Read Now <i className="item-arrow" />
                                    </Link>
                                  </div>
                                </div>
                              </article>
                            </LazyLoadComponent>
                          )
                        })}

                        <JwPagination
                          items={this.state.latex}
                          onChangePage={this.onChangePage}
                        />
                      </>
                    ) : (
                      <div className="search-result-loading" />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <Search posts={allNews} />
                <div className="recent-post mt-3">
                  <h5 className="font-weight-medium mt-0">Recent Posts</h5>
                  <ul className="list-unstyled pt-3">
                    {allNews.slice(0, 4).map(({ node }) => {
                      return (
                        <li>
                          <p className="text-teal font-weight-bold mb-1">
                            {node.publishedAt
                              ? node.publishedAt
                              : node._createdAt}
                          </p>
                          <Link
                            className="text-secondary font-weight-medium"
                            to={`/${node.slug.current}/`}
                          >
                            {node.title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="newsletter">
                  <h5 className="font-weight-medium mt-0">Newsletter</h5>
                  <p className="text-small">
                    Keep up to date with all sorts of business insights, tools
                    and resources by joining our newsletter.
                  </p>
                  <NewsletterForm id="newsletterForm-1" />
                </div>
                <FreeGuide />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SearchPageAll

export const searchAllQuery = graphql`
  query searchAllQuery {
    categoryBase: allSanityKnowledgebasecategory(
      sort: { fields: [order, knowledgebasepage___publishedAt], order: ASC }
    ) {
      edges {
        node {
          id
          title
          icon
          slug {
            current
          }
          subCategory {
            id
            title
            slug {
              current
            }
            knowledgebasepage {
              id
              title
              slug {
                current
              }
            }
          }
          knowledgebasepage {
            id
            title
            publishedAt
            slug {
              current
            }
          }
        }
      }
    }
    allSanityKnowledgeBase(
      sort: { fields: [publishedAt, _createdAt], order: DESC }
    ) {
      edges {
        node {
          _type
          id
          title
          metaDescription
          body
          publishedAt(formatString: "L")
          _createdAt(formatString: "L")
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          author {
            id
            name
            slug {
              current
            }
            image {
              asset {
                id
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
          }
          categories {
            ... on SanityKnowledgebasecategory {
              id
              title
              slug {
                current
              }
              subCategory {
                title
                slug {
                  current
                }
              }
            }
            ... on SanitySubCategory {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
    allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          _type
          title
          categories {
            title
          }
          publishedAt(formatString: "MMMM DD, YYYY")
          _createdAt(formatString: "MMMM DD, YYYY")
          body
          shortDescription
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          author {
            id
            name
            slug {
              current
            }
            image {
              asset {
                _id
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          _type
          id
          title
          discription
          slug {
            current
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          categories {
            title
          }
          author {
            id
            name
            slug {
              current
            }
            image {
              asset {
                _id
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`

import React from "react"
import WebriQForm from "@webriq/gatsby-webriq-form"

const newsletterForm = ({ id, name }) => (
  <WebriQForm
    name={name ? name : "Newsletter Form"}
    id={id ? id : "newsletterForm"}
    className="newsletter-form"
    data-form-id="5e3b73ddf8b826519570892b"
    data-thankyou-url="/thank-you"
  >
    <input
      className="form-control newsletter-input"
      type="text"
      name="Email"
      placeholder="Email"
      required
    />
    <div className="form-group mt-4 newletter-captcha">
      <div className="webriq-recaptcha" />
    </div>
    <button className="btn newsletter-submit" type="submit" aria-label="btn">
      <i className="fa fa-paper-plane-o" />
    </button>
  </WebriQForm>
)

export default newsletterForm
